import AWS from "aws-sdk";

const emailTemplate = (name, email, content) => ({
  Destination: {
    /* required */
    ToAddresses: ["lryan0901@icloud.com"]
  },
  Message: {
    Body: {
      Text: {
        Charset: "UTF-8",
        Data: `
          ${name} has reached out to you via your website contact form

          "${content}"
          Email: ${email}

          By clicking reply to this email, the contacts email will be prepopulated
          `
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: `[Fannin Mart Restaurant] Contact Request`
    }
  },
  Source: "Fisherman Websites <info@gofisherman.com>",
  ReplyToAddresses: [email]
});

const subscriptionCallback = ({ name, email, message }) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(emailTemplate(name, email, message))
    .promise();

export default subscriptionCallback;
