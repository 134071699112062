import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  Contact,
  ContactForm
} from "@fishrmn/fishrmn-components";
import emailCallback from "../utils/email.js";

import AWS from "aws-sdk";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>Fannin Mart Restaurant | Contact</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Contact"}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[1]]}
              showMap={false}
              ctas={[data.ctas.address, data.ctas.phone]}
              ctaInverted={false}
              ctaColor={"secondary"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={false}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid stackable className="component-section-container">
            <Grid.Column width={16}>
              <Contact
                phoneNumber={data.phone}
                address={{
                  street: data.address.street,
                  city: data.address.city,
                  state: data.address.state,
                  zip: data.address.zip
                }}
                hours={data.hours}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={false}
                header={"Contact Us"}
                inline={false}
                onSubmit={emailCallback}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            address {
              link
              text
              internal
            }
            phone {
              link
              text
              internal
            }
          }
          heroImages {
            url
          }
          businessName
          phone
          address {
            street
            city
            state
            zip
          }
          hours {
            open
            close
            day
            label
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          heroImages {
            url
          }
          galleryImages {
            url
          }
        }
      }
    }
  }
`;
